import styles from './FridaySummary.module.scss'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import {GET} from '../../utils/networkCallApi'
import coin from './assets/smw_coin.wav'
import { Box, Stack, Typography } from '@mui/material'
import superWin from './assets/super-mario-win.mp3'
import superLoose from './assets/super-mario-death.mp3'

type KPINetFlow = {
    WEEK: {
        value: number,
        change: number,
        budget: number
    },
    MONTH: {
        value: number,
        change: number,
        budget: number
    },
    YTD: {
        value: number,
        budget: number
    }
}

const coinSound = new Audio(coin)
const winSound = new Audio(superWin)
const looseSound = new Audio(superLoose)

const FridaySummary = ({jwt, exitFn} : {jwt: string | null, exitFn: () => void}) => {
    const [marioIsJumping, setMarioIsJumping] = useState(false)
    const [luigiIsJumping, setLuigiIsJumping] = useState(false)
    const [currentDepositJumpVolume, setCurrentDepositJumpVolume] = useState(0)
    const [currentFundJumpVolume, setCurrentFundJumpVolume] = useState(0)
    const [weeklyDepositVolume, setWeeklyDepositVolume] = useState(0)
    const [weeklyFundVolume, setWeeklyFundVolume] = useState(0)
    const [startCounting, setStartCounting] = useState(false)
    const [kpiNetFlow, setKpiNetFlow] = useState<KPINetFlow | null>(null)
    const [marioRun, setMarioRun] = useState(false)
    const [marioRunJump, setMarioRunJump] = useState(false)
    const [marioMessage, setMarioMessage] = useState(false)

    const currentJumpVolume = currentDepositJumpVolume + currentFundJumpVolume

    const playCoinSound = () => {
        coinSound.play()
    }

    const playWinSound = () => {
        winSound.play()
    }

    const playLooseSound = () => {
        looseSound.play()
    }

    useEffect(() => {
        GET('/api/stagg/volume/all', jwt).then(function (response) {
            setWeeklyDepositVolume(response.orderedLast7)
            console.log("deposits", response)
        })
        GET('/api/stagg/volume/funds', jwt).then(function (response) {
            setWeeklyFundVolume(response.orderedLast7)
            console.log("funds", response)
        })
        GET('/api/stagg/kpi/net-flow', jwt).then(function (response) {
            //setKpiNetFlow(response)
            setKpiNetFlow(response)
            console.log("Net flow", response)
        })
    },[])

    const marioJump = () => {
        setMarioIsJumping(true)
        setTimeout(() => {
            playCoinSound()
        }, 400)
        setTimeout(() => {
            setCurrentDepositJumpVolume(prev => prev + 10)
            setMarioIsJumping(false)
        }, 1000)
    }

    const luigiJump = () => {
        setLuigiIsJumping(true)
        setTimeout(() => {
            playCoinSound()
        }, 400)
        setTimeout(() => {
            setCurrentFundJumpVolume(prev => prev + 10)
            setLuigiIsJumping(false)
        }, 1000)
    }

    const goMarioRunJump = () => {
        setMarioRun(true)
        setTimeout(() => {
            setMarioRunJump(true)
        }, 500)
    }

    useEffect(() => {
        if (weeklyDepositVolume !== 0 && startCounting) {
            let numberOfJumps = weeklyDepositVolume / 10;
            let currentJump = 1;
            const jumpInterval = setInterval(() => {
                if (numberOfJumps <= currentJump) {
                    clearInterval(jumpInterval)
                }
                if (currentJumpVolume === 0 && weeklyDepositVolume === 0) {
                    return
                }
                marioJump()

                currentJump = currentJump + 1;
            }, 2000)
            return () => clearInterval(jumpInterval)
        }
    },[weeklyDepositVolume, startCounting])

    useEffect(() => {
        setTimeout(() => {
            if (weeklyFundVolume !== 0 && startCounting) {
                let numberOfJumps = weeklyFundVolume / 10;
                let currentJump = 1;
                const jumpInterval = setInterval(() => {
                    if (numberOfJumps <= currentJump) {
                        clearInterval(jumpInterval)
                    }
                    if (currentJumpVolume === 0 && weeklyFundVolume === 0) {
                        return
                    }
                    luigiJump()

                    currentJump = currentJump + 1;
                }, 2000)
                return () => clearInterval(jumpInterval)
            }
        }, 500)
    },[weeklyFundVolume, startCounting])

    useEffect(() => {
        if (currentJumpVolume > (weeklyDepositVolume + weeklyFundVolume)) {
            setTimeout(() => {
                goMarioRunJump()
            }, 3000)
            setTimeout(() => {
                if (kpiNetFlow?.WEEK?.value > kpiNetFlow?.WEEK?.budget) {
                    playWinSound()
                } else {
                    playLooseSound()
                }
                setMarioMessage(true)
            }, 5000);
        } else {
            setMarioMessage(false)
            setMarioRun(false)
            setMarioRunJump(false)
        }
    },[currentJumpVolume, weeklyDepositVolume, weeklyFundVolume])

    const kpiNetFlowPercentageGoal = Math.min((kpiNetFlow?.WEEK?.value / kpiNetFlow?.WEEK?.budget) * 100, 100)
    const jumpHeight = ((kpiNetFlow?.WEEK?.budget * kpiNetFlowPercentageGoal) / 100) - 5 + "rem"

    return (
        <div className={styles.fridaySummary}>
            <div className={styles.marioWorld}>
                <button onClick={() => exitFn()} className={styles.exitButton}>Avslutt</button>
                <div className={styles.textContainer}>
                    { startCounting && (
                        <>
                        <p>Denne uken har vi fått inn</p>
                        <h1>{currentJumpVolume} MILL.</h1>
                        <p>{currentDepositJumpVolume} i innskudd, {currentFundJumpVolume} i fond</p>
                        </>
                    )}
                    { !startCounting && (
                        <button className={styles.button} onClick={() => setStartCounting(true)}>Start opptelling!</button>
                    )}
                </div>
                <div className={styles.marioJumpWrapper}>
                    <div className={classNames(styles.coin, marioIsJumping && styles.show)}/>
                    <div className={styles.moneyBox}/>
                    <div style={{opacity: marioRun ? 0 : 1}} className={classNames(styles.mario, marioIsJumping && styles.jumping)}></div>
                    <div className={classNames(styles.luigiCoin, luigiIsJumping && styles.show)}/>
                    <div className={styles.luigiMoneyBox}/>
                    <div style={{opacity: marioRun ? 0 : 1}} className={classNames(styles.luigi, luigiIsJumping && styles.jumping)}></div>
                </div>
                <Box position="relative">
                    <Box ml={2} height={kpiNetFlow?.WEEK?.budget + "rem"} width={"1rem"} sx={{backgroundColor: "#96e4ac", transform: "translate(100px, 3px)"}}>
                        <Box width="3rem" height="3rem" sx={{backgroundColor: "green", border: "2px solid #00370f"}} position={"absolute"} top="0" left="-1rem" borderRadius={"10rem"}/>
                        <Box position={"absolute"} top="3rem" left="-6rem">
                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0H60V60L0 0Z" fill="#201240"/>
                                <g clip-path="url(#clip0_852_6933)">
                                <path d="M37.902 15.4058H34.5V11.6413H41.3364L46.2215 9H51V12.7645H44.8079C44.8079 12.7645 43.7279 12.7264 42.6758 13.3546L39.8996 14.8633C39.8996 14.8633 38.9958 15.4011 37.902 15.4011V15.4058Z" fill="#95E1BF"/>
                                <path d="M37.902 20.4504H34.5V16.6859H41.3364L46.2215 14.0493H51V17.8138H44.8079C44.8079 17.8138 43.7279 17.7757 42.6758 18.4039L39.8996 19.9078C39.8996 19.9078 38.9958 20.4456 37.902 20.4456V20.4504Z" fill="#95E1BF"/>
                                <path d="M41.3271 21.7217H34.5V25.5005H41.3271V21.7217Z" fill="#95E1BF"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_852_6933">
                                <rect width="16.5" height="16.5" fill="white" transform="translate(34.5 9)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </Box>
                    </Box>
                    { marioRun && (
                        <Box position="absolute" className={classNames(styles.flagMario, marioRun && styles.run)} sx={{bottom: marioRunJump ? `${jumpHeight}` : "0"}}>
                            { marioMessage && (
                                <Box width={"36rem"} border={"2px solid black"} sx={{backgroundColor: "white", transform: "translate(70px, 0)", p: 3}}>
                                    <Typography fontWeight={700} mb={1}>{ kpiNetFlow?.WEEK?.value > kpiNetFlow?.WEEK?.budget ? "Herlig! Blir tur på rødbrygga i kveld!" : "Aiai, Steinkjer gråter i kveld"}</Typography>
                                    <Stack spacing={0}>
                                        <Typography fontSize={"1.2rem"}>Nettoflyt: {kpiNetFlow?.WEEK?.value} mill. NOK</Typography>
                                        <Typography fontSize={"1.2rem"}>Budsjett: {kpiNetFlow?.WEEK?.budget} mill. NOK</Typography>
                                    </Stack>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </div>
        </div>
    )
}

export default FridaySummary