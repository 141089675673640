
// Images
import fixrateHQ from "./images/normal/Fixrate-HQ.svg";
import bench from "./images/normal/bench.svg";
import chopper from "./images/normal/chopper.svg";
import cinema from "./images/normal/cinema.svg";
import fighterJet from "./images/normal/fighter-jet.svg";
import hangGlider from "./images/normal/hangglider.svg";
import light from "./images/normal/light.svg";
import { ReactComponent as MoneyBag } from "./images/normal/money-bags/paths/money-bag-path.svg";
import { ReactComponent as BigMoneyBag } from "./images/normal/money-bags/paths/big-money-bag-path.svg";
import plane from "./images/normal/plane.svg";
import tesla from "./images/normal/tesla.svg";
import tree from "./images/normal/tree.svg";
import bigBank from "./images/normal/banks/Bank-big.svg";
import smallBank from "./images/normal/banks/Bank-small.svg";

import christmasFixrateHQ from "./images/christmas/Fixrate-HQ.svg";
import christmasBench from "./images/christmas/bench.svg";
import christmasChopper from "./images/christmas/chopper.svg";
import christmasCinema from "./images/christmas/cinema.svg";
import christmasFighterJet from "./images/christmas/fighter-jet.svg";
import christmasHangGlider from "./images/christmas/hangglider.svg";
import christmasLight from "./images/christmas/light.svg";
import { ReactComponent as christmasMoneyBag } from "./images/christmas/money-bags/paths/money-bag-path.svg";
import { ReactComponent as christmasBigMoneyBag } from "./images/christmas/money-bags/paths/big-money-bag-path.svg";
import christmasPlane from "./images/christmas/plane.svg";
import christmasTesla from "./images/christmas/tesla.svg";
import christmasTree from "./images/christmas/tree.svg";
import christmasBigBank from "./images/christmas/banks/Bank-big.svg";
import christmasSmallBank from "./images/christmas/banks/Bank-small.svg";

// Sounds
import orderSound from "./sounds/normal/common/order.wav";
import bigDepositSound from "./sounds/normal/deposit/big.mp3";
import mediumDepositSound from "./sounds/normal/deposit/medium.mp3";
import bigFundSound from "./sounds/normal/fund/big.wav";
import mediumFundSound from "./sounds/normal/fund/medium.wav";

import christmasOrderSound from "./sounds/christmas/common/order.mp3";
import christmasBigDepositSound from "./sounds/christmas/deposit/big.mp3";
import christmasMediumDepositSound from "./sounds/christmas/deposit/medium.mp3";
import christmasBigFundSound from "./sounds/christmas/fund/big.wav";
import christmasMediumFundSound from "./sounds/christmas/fund/medium.wav";

type CityTheme = {
    images: {
        fixrateHQ: string;
        bench: string;
        chopper: string;
        cinema: string;
        fighterJet: string;
        hangGlider: string;
        light: string;
        MoneyBag: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
            title?: string;
        }>
        BigMoneyBag: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
            title?: string;
        }>
        plane: string;
        tesla: string;
        tree: string;
        bigBank: string;
        smallBank: string;
    },
    sounds: {
        common: {
            order: string;
        },
        deposit: {
            big: string;
            medium: string;
        },
        fund: {
            big: string;
            medium: string;
        },
    }
}

type CityThemes = {
    normal: CityTheme;
    christmas: CityTheme;
}

const themes: CityThemes = {
    normal: {
        images: {
            fixrateHQ: fixrateHQ,
            bench: bench,
            chopper: chopper,
            cinema: cinema,
            fighterJet: fighterJet,
            hangGlider: hangGlider,
            light: light,
            MoneyBag: MoneyBag,
            BigMoneyBag: BigMoneyBag,
            plane: plane,
            tesla: tesla,
            tree: tree,
            bigBank: bigBank,
            smallBank: smallBank,
        },
        sounds: {
            common: {
                order: orderSound,
            },
            deposit: {
                big: bigDepositSound,
                medium: mediumDepositSound,
            },
            fund: {
                big: bigFundSound,
                medium: mediumFundSound,
            },
        }
    },
    christmas: {
        images: {
            fixrateHQ: christmasFixrateHQ,
            bench: christmasBench,
            chopper: christmasChopper,
            cinema: christmasCinema,
            fighterJet: christmasFighterJet,
            hangGlider: christmasHangGlider,
            light: christmasLight,
            MoneyBag: christmasMoneyBag,
            BigMoneyBag: christmasBigMoneyBag,
            plane: christmasPlane,
            tesla: christmasTesla,
            tree: christmasTree,
            bigBank: christmasBigBank,
            smallBank: christmasSmallBank,
        },
        sounds: {
            common: {
                order: christmasOrderSound,
            },
            deposit: {
                big: christmasBigDepositSound,
                medium: christmasMediumDepositSound,
            },
            fund: {
                big: christmasBigFundSound,
                medium: christmasMediumFundSound,
            },
        }
    }
}

const calendar = {
    1: themes.normal,
    2: themes.normal,
    3: themes.normal,
    4: themes.normal,
    5: themes.normal,
    6: themes.normal,
    7: themes.normal,
    8: themes.normal,
    9: themes.normal,
    10: themes.normal,
    11: themes.normal,
    12: themes.christmas,
}

const getCurrentTheme = () => {
    const currentMonth = new Date().getMonth() + 1

    return calendar[currentMonth]
}

export { themes, getCurrentTheme }