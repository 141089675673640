import styles from "../../app.module.scss"
import { getCurrentTheme } from "../../cityThemes/cityThemes"
import { BankDto } from "../../types"

export default function BankRow({banks, onClick} : {banks: BankDto[], onClick: (bank: BankDto) => void}) {
    const theme = getCurrentTheme()
    return (
        <ul id="banks" className={styles.banks}>
            {banks.map((bank) => (
                <li
                    id={bank.id}
                    key={bank.id}
                    className={
                        bank.totalAssets > 5000
                            ? styles.big
                            : styles.small
                    }
                    onClick={() => onClick(bank)}
                >
                    <h3>{bank.name}</h3>
                    <img
                        alt=""
                        src={
                            bank.totalAssets > 5000
                                ? theme.images.bigBank
                                : theme.images.smallBank
                        }
                    />
                </li>
            ))}
        </ul>
    )
}